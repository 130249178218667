var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-table", {
              ref: "gridWork",
              attrs: {
                title: "작업계획 목록",
                columns: _vm.gridWork.columns,
                data: _vm.gridWork.data,
                gridHeight: _vm.gridWork.height,
                isDense: true,
                bgHeaderClass: "bg-grid-header-main",
                hideBottom: true,
                isExcelDown: false,
                gridHeightAuto: true,
                filtering: false,
                isFullScreen: false,
                columnSetting: false,
                selection: "multiple",
                rowKey: "workPlanWorkId",
              },
              on: { rowClick: _vm.rowClick },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm2" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "작업계획 상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workPlanId)
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "작업계획 추가",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addRowWork },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workPlanId) &&
                            _vm.selectedWork &&
                            _vm.workUpdateMode
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "작업계획 삭제",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeRowWork },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workPlanId) &&
                            _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveWorkUrl,
                                    isSubmit: _vm.isSave2,
                                    param: _vm.workdata,
                                    mappingType: _vm.mappingType2,
                                    label: "작업계획 저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveWork,
                                    btnCallback: _vm.saveCallbackWork,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              label: "항목번호",
                              name: "sortOrder",
                              type: "number",
                            },
                            model: {
                              value: _vm.workdata.sortOrder,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "sortOrder", $$v)
                              },
                              expression: "workdata.sortOrder",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              label: "작업명",
                              name: "workPlanWorkName",
                            },
                            model: {
                              value: _vm.workdata.workPlanWorkName,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workPlanWorkName", $$v)
                              },
                              expression: "workdata.workPlanWorkName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              codeGroupCd: "WO_OPR_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "workOprTypeCd",
                              label: "작업제어구분",
                            },
                            on: { datachange: _vm.changeWorkOprType },
                            model: {
                              value: _vm.workdata.workOprTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workOprTypeCd", $$v)
                              },
                              expression: "workdata.workOprTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.workdata.workOprTypeCd !== "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                            },
                            [
                              _c("c-vendor", {
                                attrs: {
                                  editable:
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.selectedWork,
                                  label: "작업업체",
                                  name: "vendorCd",
                                },
                                model: {
                                  value: _vm.workdata.vendorCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workdata, "vendorCd", $$v)
                                  },
                                  expression: "workdata.vendorCd",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.workdata.workOprTypeCd === "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                            },
                            [
                              _c("c-dept", {
                                attrs: {
                                  type: "edit",
                                  isFirstValue: _vm.workUpdateMode,
                                  editable:
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.selectedWork,
                                  label: "작업부서",
                                  name: "workDeptCd",
                                },
                                model: {
                                  value: _vm.workdata.workDeptCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workdata, "workDeptCd", $$v)
                                  },
                                  expression: "workdata.workDeptCd",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              range: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              type: "date",
                              label: "작업기간",
                              name: "workDts",
                            },
                            model: {
                              value: _vm.workdata.workDts,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workDts", $$v)
                              },
                              expression: "workdata.workDts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              isArray: false,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              codeGroupCd: "SOP_WORK_TYPE_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "작업허가서 구분",
                              name: "workpermitTypes",
                            },
                            model: {
                              value: _vm.workdata.workpermitTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workpermitTypes", $$v)
                              },
                              expression: "workdata.workpermitTypes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              isArray: false,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              codeGroupCd: "WO_RISK_FACTOR",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "위험요인",
                              name: "workRiskFactors",
                            },
                            model: {
                              value: _vm.workdata.workRiskFactors,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workRiskFactors", $$v)
                              },
                              expression: "workdata.workRiskFactors",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "grid2",
                attrs: {
                  title: "작업계획별 서비스(외주)",
                  columns: _vm.grid2.columns,
                  data: _vm.workdata.serviceList,
                  gridHeight: _vm.gridHeight,
                  editable: _vm.editable && !_vm.disabled && _vm.selectedWork,
                  hideBottom: true,
                  isExcelDown: false,
                  filtering: false,
                  isFullScreen: false,
                  columnSetting: false,
                  selection: "multiple",
                  rowKey: "vendorCd",
                },
                on: { "table-data-change": _vm.tableDataChangeService },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable &&
                        !_vm.disabled &&
                        Boolean(_vm.param.workPlanId) &&
                        _vm.selectedWork
                          ? _c("c-btn", {
                              attrs: { label: "추가", icon: "add" },
                              on: { btnClicked: _vm.addRowService },
                            })
                          : _vm._e(),
                        _vm.editable &&
                        !_vm.disabled &&
                        Boolean(_vm.param.workPlanId) &&
                        _vm.selectedWork
                          ? _c("c-btn", {
                              attrs: { label: "제외", icon: "remove" },
                              on: { btnClicked: _vm.removeRowService },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }